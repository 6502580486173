@keyframes move-up {
  0% {
    translate: 0 25%;
  }
  100% {
    translate: 0 0;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Bobbing {
  0% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(-12.5px);
  }
}

@keyframes SecBobbing {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes Scalein {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  to {
    opacity: 0;
  }
}

@keyframes backMove {
  100% {
    background-position: 100vh 100vh;
  }
}

@keyframes fullSpin {
  0% {
    rotate: 0deg;
    scale: 1;
  }
  60% {
    scale: 0.75;
  }
  100% {
    rotate: 720deg;
    scale: 1;
  }
}

@keyframes flickerLight {
  0% {
    box-shadow: 0 0 10px var(--glow);
  }
  17% {
    box-shadow: 0 0 40px var(--glow);
  }
  34% {
    box-shadow: 0 0 20px var(--glow);
  }
  50% {
    box-shadow: 0 0 40px var(--glow);
  }
  67% {
    box-shadow: 0 0 80px var(--glow);
  }
  84% {
    box-shadow: 0 0 30px var(--glow);
  }
  100% {
    box-shadow: 0 0 80px var(--glow);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes filling {
  0%,
  50% {
    fill-opacity: 0;
  }
  80%,
  90% {
    /* fill: var(); */
    fill-opacity: 1;
  }
  100% {
    /* fill: #bac736; */
    fill-opacity: 0;
  }
}
